<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-2">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                     <span class="mr-2"> Show </span>
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select> <span class="ml-2">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-10">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search order"
                      class="form-control ml-2"
                    ></b-form-input>
                    <select class="form-control ml-2 mr-2" v-model="location" @change="customFilter" :disabled="this.$store.getters.getStaffLocationId">
                      <option :value="null" disabled>Select Location</option>
                      <option v-for="(location, index) in locations" :key="index" :value="location.locationId">{{ location.name }}</option>
                    </select>
                    <date-picker
                      v-model="date"
                      range
                      format="DD MMMM YYYY"
                      name="date"
                      :first-day-of-week="1"
                      lang="en"
                      placeholder="Select Date"
                      @pick="customFilter"
                    ></date-picker>
                    <select class="form-control ml-2 mr-2" v-model="status" @change="customFilter">
                      <option :value="null" disabled>Select Status</option>
                      <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{ status.label }}</option>
                    </select>
                  </label>
                </div>
              </div>
              
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="table"
                :items="fetchData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template>
                <!-- paymentStatus -->
                <template v-slot:cell(payment_status)="data">
                  <template v-if="data.item.payment_status == 'paid'">  
                    <b-button variant="success" size="sm">{{data.item.payment_status}}</b-button>
                  </template>
                  <template v-else-if="data.item.payment_status == 'unpaid'">  
                    <b-button variant="info" size="sm">{{data.item.payment_status}}</b-button>
                  </template>
                  <template v-else>  
                    <b-button variant="warning" size="sm">{{data.item.payment_status}}</b-button>
                  </template>
                </template>
                <!-- booking status -->
                <template v-slot:cell(status)="data">
                  <template v-if="data.item.status == 'scheduled'">  
                    <b-button variant="warning" size="sm">{{data.item.status}}</b-button>
                  </template>
                  <template v-else-if="data.item.status == 'confirmed'">  
                    <b-button variant="success" size="sm">{{data.item.status}}</b-button>
                  </template>
                    <template v-else-if="data.item.status == 'canceled'">  
                    <b-button variant="danger" size="sm">{{data.item.status}}</b-button>
                  </template>
                  <template v-else-if="data.item.status == 'completed'">  
                    <b-button variant="primary" size="sm">{{data.item.status}}</b-button>
                  </template>
                  <template v-else>  
                    <b-button variant="info" size="sm">{{data.item.status}}</b-button>
                  </template>
                </template>
                <!-- Aciton -->
                <template v-slot:cell(action)="data">                  
                  <b-button variant="outline-warning"  v-b-tooltip.hover title="View Detail" size="sm" @click.prevent="showAppointmentId = data.item.appointmentId">Detail</b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <detail-appointment :id="showAppointmentId" @close="showAppointmentId = null"></detail-appointment>
      
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import DetailAppointment from '../appointment/detail-appointment';
import PageHeader from "@/components/page-header";

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};

/**
 * Advanced table component
 */
export default {
  page: {
    title: "History Appointment",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, DatePicker, DetailAppointment },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/home"
        },
        {
          text: "User",
          href: "/user"
        },
        {
          text: "History Appointment",
          active: true
        }
      ],
      user: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
      },
      showAppointmentId: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "createdAt",
      sortDesc: false,
      fields: [
        'No',
        { key: "booking_code", label: "Order", sortable: true },
        { key: "location", label: "Clinic", sortable: true }, 
        { key: "date", label: "Date", sortable: true }, 
        { key: "time", label: "Time", sortable: true }, 
        { key: "payment_status", label: "Payment Status", sortable: true }, 
        { key: "status", label: "Status", sortable: true }, 
        'Action'
      ],
      location: this.$store.getters.getStaffLocationId,
      locations: [],
      status: null,
      statuses: [
        {
          value: 'scheduled',
          label: 'Scheduled'
        },
        {
          value: 'confirmed',
          label: 'Confirmed'
        },
        {
          value: 'in progress',
          label: 'In Progress'
        },
        {
          value: 'canceled',
          label: 'Canceled'
        },
        {
          value: 'completed',
          label: 'Completed'
        }
      ],
      date: [],
      isError: false,
      errorMessage: null
    };
  },
  computed: {
    title() {
      return `${this.user.firstname} ${this.user.lastname} History Booked Appointment`
    }
  },
  mounted() {
    this.fetchUser()
    this.fetchLocation()
  },
  methods: {
    fetchUser() {
      this.$axios.get(`/user/${this.$route.params.id}`)
        .then( (response) => { 
          const result = response.data.result

          let phoneNumber = null
          if (result.user.phone.toString().substring(0,3) == "+62") {
            phoneNumber = result.user.phone.toString()
          } else if(result.user.phone.toString().substring(0,2) == "62"){ 
            phoneNumber = `+${result.user.phone.toString()}`
          } else if(result.user.phone.toString().charAt(0) == "0"){ 
            phoneNumber = `+62${result.user.phone.toString().substring(1)}`
          } else {
            phoneNumber = `${result.user.phone.toString()}`
          }

          this.user = {
            firstname: result.user.firstname.charAt(0).toUpperCase() + result.user.firstname.slice(1),
            lastname: result.user.lastname.charAt(0).toUpperCase() + result.user.lastname.slice(1),
            phone: phoneNumber.substring(3),
            email: result.user.email,
          }

        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    },
    async fetchData(ctx) { 
      console.log(ctx)
      const promise = this.$axios.get(`appointment`, {
        params: {
        page: ctx.currentPage,
        limit: ctx.perPage,
        search: ctx.filter,
        userId: this.$route.params.id, 
        locationId: this.location,
        status: this.status,
        startDate: (this.date.length)? this.date[0] : null,
        endDate: (this.date.length)? this.date[1] : null,
        sortBy: ctx.sortBy,
        sortType: (ctx.sortDesc)? 1 : -1
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        console.log(response)
       
        const items = response.data.result.appointments
        this.totalRows = response.data.result.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    fetchLocation() {
      this.$axios
        .get(`location`)
        .then((response) => {
          let result = response.data.result.location;
          this.locations = result;
        });
    },
    async customFilter() {
      await this.fetchData({
        currentPage: this.currentPage,
        filter: this.filter,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      })
      this.$refs.table.refresh()
    }
  }
};
</script>